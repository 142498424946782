import { useQuery } from "@tanstack/react-query";
import Checklist from "./icons/Checklist";
import HandShake from "./icons/HandShake";
import People from "./icons/People";
import Expanses from "./moleculs/chart/Expanses";
import TotalIncome from "./moleculs/chart/TotalIncome";
import { getSummaryService } from "services/summary";
import { formatRupiah } from "utils/normalize";
import { useParams } from "react-router-dom";

function Grafix() {
  const params = useParams()

  const summaryQuery = useQuery({
    queryFn: () => getSummaryService(params?.event_id),
    queryKey: ['summary', params?.event_id],
  })

  const summaryData = summaryQuery.data
  return (
    <section className="w-full flex gap-3 mb-3">
      <div className="w-9/12">
        <div className="app-shadow mb-3 px-10 h-40 flex justify-between items-center bg-default-opacity">
          <div className="flex">
            <div className="w-10 h-10 rounded-full bg-white-opacity flex justify-center mt-auto items-center">
              <People />
            </div>
            <div className="ml-5 self-start">
              <p className="font-medium text-lg">Vendor</p>
              <p className="font-bold text-3xl text-default">{summaryData?.total_vendor ?? 0}</p>
            </div>
          </div>
          <div className="flex">
            <div className="w-10 h-10 rounded-full bg-white-opacity flex justify-center mt-auto items-center">
              <HandShake />
            </div>
            <div className="ml-5">
              <p className="font-medium text-lg">Submission Vendor</p>
              <p className="font-bold text-3xl text-default">{summaryData?.total_submission_vendor ?? 0}</p>
            </div>
          </div>
          <div className="flex">
            <div className="w-10 h-10 rounded-full bg-white-opacity flex justify-center mt-auto items-center">
              <Checklist
                width={25}
                height={25}
                color="#F77C32"
              />
            </div>
            <div className="ml-5">
              <p className="font-medium text-lg">Approved Booth</p>
              <p className="font-bold text-3xl text-default">{summaryData?.total_approved_booth}</p>
            </div>
          </div>
        </div>
        <div className="app-shadow card">
          <div className="card-header">
            <h1>Total Income</h1>
            <h2 className=" text-default text-xl font-bold tracking-[0.5px] leading-[28px] mt-2 mb-6">{formatRupiah(summaryData?.total_income ?? 0)}</h2>
            <div className=" max-h-[300px]">
              <TotalIncome data={summaryData?.monthly_incomes ?? []} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/12 app-shadow card flex">
        <div className="card-header flex flex-col flex-grow">
          <h1>Total Expenses</h1>
          <h2 className=" text-default text-xl font-bold tracking-[0.5px] leading-[28px] mt-2 mb-6">{formatRupiah(summaryData?.total_expense ?? 0)}</h2>
          <dvi className="flex-grow relative">
            <Expanses data={summaryData?.monthly_expenses ?? []} />
          </dvi>
        </div>
      </div>
    </section>
  );
}

export default Grafix;