import ilustration from '../assets/images/event-ilustration.svg';
import EventItem from './atoms/EventItem';
import { useContext } from 'react';
import ModalContext from 'context/ModalContext';
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";


function Event({ dataEvent, showDropdown = true, seeEvent = true, openPopupDelete, openPopupDuplicate, openPopupReset, openModalEdit, openModalBanner, isAdd = true }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { setModal } = useContext(ModalContext);
    const eventView = dataEvent.length > 0 ?
        <div className="grid grid-cols-3 gap-4">
            {
                dataEvent.map(event => {
                    return <EventItem
                        event={event}
                        showDropdown={showDropdown}
                        seeEvent={seeEvent}
                    />
                })
            }
        </div>
        :
        <>
            <img
                className='w-2/6 mx-auto'
                src={ilustration}
                alt="ilustration"
            />
            <p className='text-center mt-3'>Lest find some idea and create something great!</p>
        </>

    return (
        <section className='mb-5'>
            <div className="flex justify-end mb-5">
                {
                    isAdd ?
                        <>
                            {
                                profile?.permissions !== undefined ?
                                    <>
                                        {
                                            profile?.status === 'owner' || profile.status === 'admin' ?
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'create event') &&
                                                        <button
                                                            onClick={() => setModal('add_event')}
                                                            className="btn px-5"
                                                        >Add Event</button>
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                        </>
                        :
                        <></>
                }
            </div>
            {
                eventView
            }
        </section>
    );
}

export default Event;