import InputWithLabel from "components/atoms/InputWithLabel";
import NewMemberContext from "context/NewMemberContext";
import SelectInputLabel from "components/atoms/SelectInputLabel";
import { useContext, useState } from "react";
import ComplainContext from "context/ComplainContext";
import Popup from "components/Modal/Popup";
import Checklist from "components/icons/Checklist";
import ModalContext from "context/ModalContext";
import { toast } from "react-toastify";

function FormAssignComplain({ dataComplain }) {
    const { membersEvent } = useContext(NewMemberContext);
    const { assignMemberToComplain } = useContext(ComplainContext);
    const [memberId, setMemberId] = useState(null);
    const { setPopup, setModal } = useContext(ModalContext);

    const changeDataMembers = membersEvent.map(member => {
        member.label = member.email;
        member.value = member.id;
        return member;
    });

    const submitAssignMemberToComplain = async () => {
        const FD = new FormData();

        FD.append('member_id', memberId);
        FD.append('complaint_id', dataComplain.id);

        const reqAssignMemberToComplain = await assignMemberToComplain(FD);
        if (reqAssignMemberToComplain.status === 200) {
            toast.success('Success assign member to complain');
            setModal(undefined);
            setPopup(undefined);
        }
    }

    return (
        <>
            {
                Object.keys(dataComplain).length > 0 &&
                <form onSubmit={event => {
                    event.preventDefault();
                    setPopup('assign_member_to_complain');
                }}>
                    <InputWithLabel
                        classInput={'!mb-3'}
                        label={'Vendor Name'}
                        value={dataComplain.vendor_name}
                    />
                    <InputWithLabel
                        classInput={'!mb-3'}
                        label={'Title'}
                        value={dataComplain.title}
                    />
                    <SelectInputLabel
                        label={'Member'}
                        options={changeDataMembers}
                        classInput={` !text-base !font-medium capitalize !tracking-[0.48px] !p-0 !px-5 valid:!empty-form`}
                        onChange={(e) => setMemberId(e.target.value)}
                        required={true}
                    />
                    <button id="btn_assign_member_to_complain" hidden></button>
                </form>
            }
            <Popup
                action={submitAssignMemberToComplain}
                icon={<Checklist color='#F77C32' height={35} width={35} />}
                type="assign_member_to_complain"
                textBtn="Proceed"
            >
                Are you sure want to proceed this assignment?
            </Popup>
        </>
    );
}

export default FormAssignComplain;