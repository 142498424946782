import arrow from '../../assets/images/arrow-down.svg';

function ArrowDown({ action }) {
  return <img 
    onClick={ action }
    src={arrow} 
    alt="arrow down" 
    className='cursor-pointer' 
  />
}

export default ArrowDown;