import { createColumnHelper } from "@tanstack/react-table";
import VendorContext from "context/VendorContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableComponent from "components/Table";
import ModalBanner from "components/Modal/ModalBanner";
import ModalContext from "context/ModalContext";
import BoothContext from "context/BoothContext";
import { Badge } from "flowbite-react";
import Checklist from "components/icons/Checklist";
import Xicon from "components/icons/Xicon";
import Popup from "components/Modal/Popup";
import { STATUS_BOOKING } from "helpers/StatusBooking";

function EventVendors() {
    const [loadingDetailVendor, setLoadingDetailVendor] = useState(false);
    const { vendor, getVendorByEvent, currentPage, totalPage, getDetailVendor, vendorDetail, vendorId, setVendorId, acceptVendor, rejectVendor } = useContext(VendorContext);
    const { setModal } = useContext(ModalContext);
    const { boothId, boothNumber } = useContext(BoothContext)
    const params = useParams();

    const columnHelper = createColumnHelper();

    const vendorColumn = useMemo(
        () => [
            columnHelper.accessor('vendor_name', {
                header: 'Vendor Name'
            }),
            columnHelper.accessor('vendor_email', {
                header: 'Email',
            }),
            columnHelper.accessor('vendor_phone', {
                header: 'Phone',
                cell: info => {
                    return (
                        <>
                            {
                                info.renderValue() == null ? <div className="text-center">-</div> : info.renderValue()
                            }
                        </>
                    )
                }
            }),
        ], []
    )

    useEffect(() => {
        getVendorByEvent(params.event_id);
    }, []);

    const handleDetailVendor = async (data) => {
        setLoadingDetailVendor(true);
        setModal('detail_vendor');
        setVendorId(data.original.id);
        const fetchData = await getDetailVendor(data.original.id, params.event_id);
        if (fetchData.success) {
            setLoadingDetailVendor(false);
        }
    }

    const handleAcceptVendor = async () => {
        const FD = new FormData();

        FD.append('booth_id', boothId);
        FD.append('vendor_id', vendorId);

        acceptVendor(FD);
    }

    const handleRejectVendor = async () => {
        const FD = new FormData();

        FD.append('booth_id', boothId);
        FD.append('vendor_id', vendorId);

        rejectVendor(FD);
    }

    const handleLoadPage = async page => {
        getVendorByEvent(params.event_id, page)
    }

    return (
        <>
            <main className="px-5 mt-5">
                <div className="app-shadow py-5 px-5">
                    <TableComponent
                        data={vendor}
                        columns={vendorColumn}
                        enableClickRow={true}
                        actionClickRow={(data) => { handleDetailVendor(data) }}
                        totalPage={totalPage}
                        currentPage={currentPage}
                        showPagination={true}
                        actionLoadPage={handleLoadPage}
                    />
                </div>
            </main>
            <ModalBanner
                choosedModal={'propose_booth'}
                title={'Add Booth Category'}
                textButton="Submit"
                actionClearForm={() => { }}
                idBtnSubmit="btn_propose_booth"
            >
                jdnjsan
            </ModalBanner>
            <ModalBanner
                choosedModal={'detail_vendor'}
                title={'Detail Vendor'}
                textButton={null}
                actionClearForm={() => { }}
                useModalFooter={false}
            >
                {
                    loadingDetailVendor ?
                        <>
                            <div className="flex justify-center h-60 items-center">
                                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-default" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                Loading...
                            </div>
                        </>
                        :
                        vendorDetail !== null ?
                            <>
                                <label htmlFor="">Vendor Name</label>
                                <div className="w-full h-10 py-2 px-3 rounded-full bg-background ring-1 ring-appgray capitalize !mb-4">
                                    {vendorDetail.fullname}
                                </div>
                                <label htmlFor="">Phone Number</label>
                                <div className="w-full h-10 py-2 px-3 rounded-full bg-background ring-1 ring-appgray capitalize !mb-4">
                                    {vendorDetail.phone_number !== null ? vendorDetail.phone_number : <p className="text-moregray">No phone number</p>}
                                </div>
                                <label htmlFor="">Booth List</label>
                                <div className="rounded-xl bg-background p-4 space-y-3 ring-1 ring-appgray">
                                    {
                                        vendorDetail.booking_list.length > 0 ?
                                            vendorDetail.booking_list.map(bl => {
                                                return (
                                                    <div className="flex justify-between items-center">
                                                        <div className="flex justify-between w-full">
                                                            <div className="flex">
                                                                <h1 className="mr-1">{bl.booth_number}</h1>
                                                                <span className="mr-3">({bl.category_booth_name})</span>
                                                            </div>
                                                            <Badge className={`capitalize ${STATUS_BOOKING[bl.status]}`}>{bl.status.replace('_', ' ')}</Badge>
                                                        </div>
                                                        {/* {
                                                            bl.status === 'paid' &&
                                                            <>
                                                            </>
                                                        }
                                                        {
                                                            bl.status === 'waiting_payment' &&
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        // setPopup('accept_vendor')
                                                                        // setBoothId(bl.booth_id)
                                                                        // setBoothNumber(bl.booth_number)
                                                                    }}
                                                                >
                                                                    Approve Payment
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setPopup('reject_vendor')
                                                                        setBoothId(bl.booth_id)
                                                                        setBoothNumber(bl.booth_number)
                                                                    }}
                                                                >
                                                                    Reject
                                                                </Dropdown.Item>
                                                            </Dropdown>
                                                        }
                                                        {
                                                            bl.status === 'pending' &&
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setPopup('accept_vendor')
                                                                        setBoothId(bl.booth_id)
                                                                        setBoothNumber(bl.booth_number)
                                                                    }}
                                                                >
                                                                    Approve
                                                                </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setPopup('reject_vendor')
                                                                            setBoothId(bl.booth_id)
                                                                            setBoothNumber(bl.booth_number)
                                                                        }}
                                                                    >
                                                                        Reject
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={async () => {
                                                                            const reqBooth = await getCategoryBooth(params.event_id);

                                                                        if (reqBooth.status === 200) {
                                                                            setModal('propose_booth');
                                                                        }
                                                                    }}
                                                                >
                                                                    Propose
                                                                </Dropdown.Item>
                                                            </Dropdown>
                                                        }
                                                        {
                                                            bl.status === 'propose' &&
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setPopup('reject_vendor')
                                                                        setBoothId(bl.booth_id)
                                                                        setBoothNumber(bl.booth_number)
                                                                    }}
                                                                >
                                                                    Reject
                                                                </Dropdown.Item>
                                                            </Dropdown>
                                                        }
                                                        {
                                                            bl.status === 'reject' &&
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={async () => {
                                                                        const reqBooth = await getCategoryBooth(params.event_id);

                                                                        if (reqBooth.status === 200) {
                                                                            setModal('propose_booth');
                                                                        }
                                                                    }}
                                                                >
                                                                    Propose
                                                                </Dropdown.Item>
                                                            </Dropdown>
                                                        } */}
                                                    </div>
                                                )
                                            })
                                            :
                                            <p className="text-moregray">
                                                No booth found
                                            </p>
                                    }
                                </div>
                            </>
                            :
                            <></>
                }
            </ModalBanner>
            <Popup
                action={() => handleAcceptVendor()}
                icon={<Checklist color={'#10B986'} width={35} height={45} />}
                type="accept_vendor"
                textBtn="Accept"
            >
                Are you sure you want to approve booth <strong>#{boothNumber}</strong>?
            </Popup>
            <Popup
                action={() => handleRejectVendor()}
                icon={<Xicon color={'#B91010'} width={40} height={40} />}
                type="reject_vendor"
                textBtn="Reject"
            >
                If you reject booth <strong>#{boothNumber}</strong>, you still can propose another booth to this vendor.
                you sure want to reject?
            </Popup>
        </>
    );
}

export default EventVendors;