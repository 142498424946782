import React, { useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    PaginationState,
    getFilteredRowModel,
    getPaginationRowModel,
    ColumnDef,
    OnChangeFn,
} from "@tanstack/react-table";
import Angle from "./icons/Angle";

function TableComponent({ data, columns, totalPage, currentPage, actionLoadPage, pageSize = 15, enableClickRow = false, actionClickRow, showPagination = false }) {
    console.log(data)
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    useState(() => {
        table.setPageSize(pageSize);
    }, [])

    return (
        <>
            <div className=" overflow-x-scroll hide-scrollbar">
                <table className="responsive-table">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {
                        data.length > 0 ?
                            <>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        enableClickRow ?
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map(cell => {
                                                    if (cell.column.id !== 'status') {
                                                        return <td className="td-class cursor-pointer" onClick={() => actionClickRow(row)} key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    }

                                                    return <td className="td-class" key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>

                                                })}
                                            </tr>
                                            :
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map(cell => (
                                                    <td className="td-class" key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                    ))}
                                </tbody>
                            </>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={table.getAllColumns().length} className="text-center text-moregray">No data available on table</td>
                                </tr>
                            </tbody>
                    }
                </table>
            </div>
            {
                showPagination && data.length > 0 &&
                <Pagination table={table} totalPage={totalPage} actionLoadPage={actionLoadPage} currentPage={currentPage} />
            }
        </>
    );
}

const Pagination = ({ table, totalPage, actionLoadPage, currentPage }) => {
    let listPaging = [];

    for (let i = 0; i < totalPage; i++) {
        if (i + 1 === parseInt(currentPage)) {
            listPaging.push(<div className="w-[24px] h-[24px] bg-default text-white flex justify-center items-center cursor-pointer rounded-lg" onClick={() => {
                actionLoadPage(i + 1)
            }}>{i + 1}</div>)
        } else {
            listPaging.push(<div className="w-[24px] h-[24px] bg-appgray flex justify-center items-center cursor-pointer rounded-lg" onClick={() => { actionLoadPage(i + 1) }}>{i + 1}</div>)
        }
    }

    return (
        <div className="flex justify-between w-full border-t-2 border-appblack mt-3 px-3 py-2">
            <p>Showing page {parseInt(currentPage)} of {totalPage} page entries</p>
            <div className="flex space-x-4">
                {
                    parseInt(currentPage) === 1 ?
                        <div className={`w-[24px] h-[24px] rounded-lg bg-appgray flex justify-center items-center cursor-not-allowed`}>
                            <Angle color='#B5B5B5' width={16} height={16} rotate={90} />
                        </div>
                        :
                        <div className={`w-[24px] h-[24px] rounded-lg bg-default flex justify-center items-center cursor-pointer`} onClick={() => actionLoadPage(parseInt(currentPage) - 1)} >
                            <Angle color='#FFFFFF' width={16} height={16} rotate={90} />
                        </div>
                }
                <div className="flex space-x-2">
                    {listPaging}
                </div>
                {
                    parseInt(currentPage) === totalPage ?
                        <div className="w-[24px] h-[24px] rounded-lg bg-appgray flex justify-center items-center cursor-not-allowed">
                            <Angle color='#B5B5B5' width={16} height={16} rotate={270} />
                        </div>
                        :
                        <div className="w-[24px] h-[24px] rounded-lg bg-default flex justify-center items-center cursor-pointer" onClick={() => actionLoadPage(parseInt(currentPage) + 1)} >
                            <Angle color='#FFFFFF' width={16} height={16} rotate={270} />
                        </div>
                }
            </div>
        </div>
    )
}

export default TableComponent;