import { useContext, useEffect, useMemo, useState, } from "react";
import Event from "../components/Event";
import Grafix from "../components/Grafix";
import Header from "../components/Header";
import MemberList from "../components/MemberList";
import { createColumnHelper } from "@tanstack/react-table";
import { Badge } from "flowbite-react";
import NewEventContext from "context/NewEventContext";
import NewMemberContext from "context/NewMemberContext";
import ModalEvent from "components/Modal/ModalEvent";
import Copy from "components/icons/Copy";
import Reset from "components/icons/Reset";
import Popup from "components/Modal/Popup";
import Trash from "components/icons/Trash";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, QueryClient, QueryClientProvider, useMutation, useQuery } from "@tanstack/react-query";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import { toast } from "react-toastify";
import VendorListGlobal from "components/VendorListGlobal";
import { getTenantService } from "services/tenant";

const queryClient = new QueryClient()

function NewGlobalHome() {
    const [eventsData, setEventsData] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [page, setPage] = useState(1);
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { selectedEvent, eventId } = useContext(NewEventContext);
    const { getMembers, members } = useContext(NewMemberContext);

    const meta = document.getElementsByName('viewport')

    const reqGetEvents = async () => {
        return API.get(`/event?status=cooming_soon&page=${page}`);
    }

    const getTenantQuery = useQuery({
        queryKey: ['tenant-query'],
        queryFn: () => {
            return getTenantService(1);
        }
    })

    useEffect(() => {
        if (getTenantQuery.data !== undefined) {
            setTenants(getTenantQuery.data.data.items);
        }
        meta[0].setAttribute('content', 'width=1440')
        getMembers();
    }, []);

    const { ref, inView } = useInView()

    const {
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        isLoading,
    } = useInfiniteQuery(
        ['projects'],
        async ({ pageParam = 1 }) => {
            const res = await reqGetEvents();
            setEventsData((curData) => {
                return [...curData, ...res.data.data.items]
            });

            if (res.data.links.next !== '') {
                setPage(res.data.links.next.substring(
                    res.data.links.next.length - 1,
                    res.data.links.next.length
                ))
            }

            return res.data
        },
        {
            getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
            getNextPageParam: (lastPage) => lastPage?.links?.next !== "" ? lastPage.links.next.substring(lastPage.links.next.length - 1, lastPage.links.next) : undefined,
        },
    )

    const columnHelper = createColumnHelper();

    const memberColumns = useMemo(
        () => [
            columnHelper.accessor('fullname', {
                header: 'Name',
            }),
            columnHelper.accessor('email', {
                header: 'Email',
            }),
            columnHelper.accessor('is_active', {
                header: 'Status',
                cell: (info) => {
                    const view = info.renderValue() ?
                        <Badge
                            className="rounded-full px-2 w-fit text-[#10B986] bg-badge-success"
                        >
                            Activated
                        </Badge>
                        :
                        <Badge
                            className="rounded-full px-2 w-fit text-[#B91010] bg-badge-error"
                        >
                            Inactivated
                        </Badge>
                        ;

                    return view;
                }
            }),
        ],
        []);

    useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [inView]);

    const addMutation = useMutation({
        mutationFn: async (data) => {
            return API.post(`event`, data);
        },
        onSuccess: res => {
            setEventsData(currData => {
                return [res.data.data, ...currData]
            })

            toast.success('Success create event.')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    const deleteMutation = useMutation({
        mutationFn: async (data) => {
            return API.delete(`event/delete/${eventId}`);
        },
        onSuccess: res => {
            setEventsData(currData => {
                return currData.filter(event => event.id !== eventId);
            })

            toast.success('Success delete event.')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    const editMutation = useMutation({
        mutationFn: async (data) => {
            return API.post(`/event-update`, data);
        },
        onSuccess: res => {
            setEventsData(currData => {
                return currData.map(event => {
                    if (event.id === res.data.data.id) {
                        event = res.data.data;
                    }

                    return event;
                });
            })

            toast.success('Success edit event.')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    const duplicateMutation = useMutation({
        mutationFn: async () => {
            const FD = new FormData();
            FD.append('event_id', eventId);
            return API.post('/event/replicate', FD)
        },
        onSuccess: res => {
            setEventsData(currData => {
                return [res.data.data, ...currData]
            })

            toast.success('Success dulicate event.')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    const resetMutation = useMutation({
        mutationFn: async () => {
            const FD = new FormData();
            FD.append('event_id', eventId);
            return API.post('/event/replicate', FD)
        },
        onSuccess: res => {
            setEventsData(currData => {
                return [res.data.data, ...currData]
            })

            toast.success('Success reset event.')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <main className="bg-background">
                    <Header className="py-5 px-14" />
                    <div className="px-14 py-5">
                        {
                            profile?.status !== undefined &&
                                profile.status === 'owner' ||
                                profile.status === 'admin' ?
                                <>
                                    <Grafix />
                                    <div className="grid grid-cols-2 gap-3">
                                        <MemberList
                                            dataMember={members}
                                            memberColumns={memberColumns}
                                            pageSize={5}
                                        />
                                        <VendorListGlobal
                                            dataMember={tenants}
                                            memberColumns={memberColumns}
                                            pageSize={5}
                                        />
                                    </div>
                                </>
                                :
                                <></>
                        }
                        {
                            isLoading ?
                                <div className="flex justify-center h-60 app-shadow items-center">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-default" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    Loading...
                                </div>
                                :
                                <>
                                    <Event dataEvent={eventsData} />
                                    {
                                        isFetchingNextPage ?
                                            <div className="flex justify-center mb-5">
                                                <button
                                                    className="btn"
                                                    ref={ref}
                                                    onClick={() => fetchNextPage()}
                                                    disabled={!hasNextPage || isFetchingNextPage}
                                                >
                                                    Loading More...
                                                </button>
                                            </div>
                                            :
                                            hasNextPage ?
                                                <div className="flex justify-center mb-5">
                                                    <button
                                                        className="btn"
                                                        ref={ref}
                                                        onClick={() => fetchNextPage()}
                                                        disabled={!hasNextPage || isFetchingNextPage}
                                                    >
                                                        Load Newer
                                                    </button>
                                                </div>
                                                :
                                                <></>
                                    }
                                </>
                        }
                    </div>
                </main >
            </QueryClientProvider>
            <ModalEvent
                choosedModal="add_event"
                title="Add Event"
                type="add"
                action={addMutation}
            />
            <ModalEvent
                choosedModal="edit_event"
                title="Edit Event"
                type="edit"
                action={editMutation}
            />
            <Popup
                action={deleteMutation}
                icon={<Trash width={35} height={35} />}
                type="delete_event"
                textBtn="Delete"
                isMutate={true}
            >
                Are you sure you want to delete event <strong>#{selectedEvent}</strong>?
                <br />
                This event will permanently delete
            </Popup>
            <Popup
                action={duplicateMutation}
                icon={<Copy />}
                type="duplicate_event"
                textBtn="Duplicate"
                isMutate={true}
            >
                Event <strong>#{selectedEvent}</strong> will be duplicate now
                <br />
                Are you sure want duplicate this event?
            </Popup>
            <Popup
                action={resetMutation}
                icon={<Reset />}
                type="reset_event"
                textBtn="Reset"
                isMutate={true}
            >
                Event <strong>#{selectedEvent}</strong> would send to history and duplicate,
                <br />
                Reset this event now?
            </Popup>
        </>
    );
}

export default NewGlobalHome;
