import { NavLink, useParams } from "react-router-dom";
import NavigationItem from "./atoms/NavigationItem";
import Booth from "./icons/Booth";
import Complain from "./icons/Complain";
import Equipment from "./icons/Equipment";
import Home from "./icons/Home";
import Invoice from "./icons/Invoice";
import Logo from "./icons/Logo";
import Members from "./icons/Members";
import Purchase from "./icons/Purchase";
import Setting from "./icons/Setting";
import Vendor from "./icons/Vendors";
import Vouchers from "./icons/Vouchers";
import Questios from "./icons/Questions";
import { MainsStack } from "routes";

function SideBar() {
    const params = useParams();
    return (
        <div className="h-screen pl-5 py-5 min-w-[280px] fixed z-[50] overflow-scroll hide-scrollbar">
            <div className=" app-shadow px-7 py-3">
                <Logo />
                <div className="nav mt-10 relative">
                    <ul>
                        <NavLink
                            to={`/admin/event/${params.event_id}/home`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Home />}
                                title="Home"
                            />
                        </NavLink>
                        <NavLink
                            to={MainsStack.eventForm.replace(':event_id', params.event_id)}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Questios />}
                                title="Questions"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/booth`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Booth />}
                                title="Booth"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/vendors`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Vendor />}
                                title="Vendors"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/inventory`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Equipment />}
                                title="Inventory"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/purchase`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Purchase />}
                                title="Stock In"
                            />
                        </NavLink>
                        <NavLink
                            to={MainsStack.eventPurchaseOut.replace(':event_id', params.event_id)}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Purchase />}
                                title="Stock Out"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/invoice`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Invoice />}
                                title="Invoice"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/members`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Members />}
                                title="Members"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/complain`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Complain />}
                                title="Complain"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/settings`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Setting />}
                                title="Settings"
                            />
                        </NavLink>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SideBar;