import { useContext, useEffect, useMemo, useState } from "react";
import BoothContext from "context/BoothContext";
import { useParams } from "react-router-dom";
import Ilustration from "../assets/images/event-ilustration.svg";
import ModalBanner from "components/Modal/ModalBanner";
import ModalContext from "context/ModalContext";
import BoothCategoryForm from "components/form/BoothCategoryForm";
import Image from "components/atoms/Image";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "components/Table";
import { Dropdown, Tooltip } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import BoothForm from "components/form/BoothForm";
import Popup from "components/Modal/Popup";
import Trash from "components/icons/Trash";
import VendorContext from "context/VendorContext";
import VendorList from "components/VendorList";
import Checklist from "components/icons/Checklist";
import Loading from "components/Loading";
import { toast } from 'react-toastify';
import ModalDetailPayment from "components/Modal/ModalDetailPayment";
import ModalLuckySpin from "components/Modal/ModalLuckySpin";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import useEventStore from "store/event";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import Add from "components/icons/Add";
import { Modal } from "flowbite-react";

function EventBooth() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const [imgUrl, setImgUrl] = useState(null);
    const { eventDetail } = useEventStore((state) => ({ eventDetail: state.eventDetail }), shallow)
    const [loadingVendor, setLoadingVendor] = useState(false);
    const [type, setType] = useState('add');
    const params = useParams();
    const { deleteBoothCategory, categoryBooth, booth, categoryName, setCategoryName, boothId, boothNumber, getCategoryBooth, clearCategoryBoothForm, clearBoothForm, setBoothNumber, setBoothPrice, setDescription, setBoothId, deleteBooth, categoryBoothId, setCategoryBoothId, setRejectedBoothId, checkPayment, changeBoothCategory, getBoothByCategory } = useContext(BoothContext);
    const { setModal, setPopup, modal } = useContext(ModalContext);
    const { getVendorByBooth, vendor, setPriorityVendor, vendorId, setVendorId, vendorName, setVendorName, resetPriority, } = useContext(VendorContext);
    const [popUpText, setPopUpText] = useState('set');
    const [typeActionCategory, setTypeActionCategory] = useState('add');

    const columnHelper = createColumnHelper();

    const boothColumn = useMemo(
        () => [
            columnHelper.accessor('number', {
                header: 'Booth Number'
            }),
            columnHelper.accessor('img_url', {
                header: 'Booth Map',
                cell: info => {
                    return (
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setImgUrl(info.renderValue())
                                setModal('img_booth')
                                setBoothNumber(info.row.original.number)
                            }}
                        >
                            <Image
                                path={info.renderValue()}
                                style={{
                                    width: 160,
                                    height: 80
                                }}
                            />
                        </div>
                    )
                }
            }),
            columnHelper.accessor('book_by', {
                header: 'Booked By',
                cell: info => {
                    return (
                        <>
                            {
                                info.renderValue() == null ?
                                    <div>-</div>
                                    :
                                    info.renderValue() === 'waiting_payment' ?
                                        <div className="text-app-green">Waiting Payment {info.row.original.payment_image !== null && <p className="text-app-red">(Waiting for approval)</p>}</div>
                                        :
                                        info.renderValue() === 'reject_payment' ?
                                            <div className="text-app-red">Payment Rejected</div>
                                            :
                                            <Tooltip content={info.row.original.vendor_phone !== null ? info.row.original.vendor_phone : 'Not Set'}>
                                                {
                                                    info.renderValue()
                                                }
                                            </Tooltip>
                            }
                        </>
                    )
                }
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: info => {
                    return (
                        <>
                            <div>
                                {
                                    checkPermission(profile?.permissions, 'view vendor') ||
                                        checkPermission(profile?.permissions, 'update booth') ||
                                        checkPermission(profile?.permissions, 'delete booth') ||
                                        checkPermission(profile?.permissions, 'set priority') ||
                                        checkPermission(profile?.permissions, 'check payment booth') ?
                                        <Dropdown
                                            renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                        >
                                            {
                                                checkPermission(profile?.permissions, 'view vendor') &&
                                                <Dropdown.Item
                                                    onClick={async () => {
                                                        setLoadingVendor(true);
                                                        setBoothId(info.row.original.id);
                                                        setModal('list_vendor');
                                                        setRejectedBoothId(info.row.original.id);
                                                        setBoothNumber(info.row.original.number);
                                                        const reqListVendor = await getVendorByBooth(info.row.original.id);
                                                        if (reqListVendor.success) {
                                                            setLoadingVendor(false);
                                                        }
                                                    }}
                                                >
                                                    Vendor List
                                                </Dropdown.Item>
                                            }
                                            {
                                                checkPermission(profile?.permissions, 'update booth') &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setBoothId(info.row.original.id);
                                                        setBoothNumber(info.row.original.number)
                                                        setBoothPrice(info.row.original.price.substring(0, info.row.original.price.length - 3))
                                                        setDescription(info.row.original.description)
                                                        setType('edit');
                                                        setModal('add_booth')
                                                    }}
                                                >
                                                    Edit
                                                </Dropdown.Item>
                                            }
                                            {
                                                checkPermission(profile?.permissions, 'delete booth') &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setBoothId(info.row.original.id);
                                                        setBoothNumber(info.row.original.number);
                                                        setPopup('delete_booth');
                                                    }}
                                                >
                                                    Delete
                                                </Dropdown.Item>
                                            }
                                            {
                                                info.row.original.is_available === 1 &&
                                                checkPermission(profile?.permissions, 'set priority') &&
                                                <>
                                                    <Dropdown.Item
                                                        onClick={async () => {
                                                            setLoadingVendor(true);
                                                            setBoothId(info.row.original.id);
                                                            setModal('list_priority');
                                                            const reqListVendor = await getVendorByBooth(info.row.original.id, true);
                                                            if (reqListVendor.success) {
                                                                setLoadingVendor(false);
                                                            }
                                                        }}
                                                    >
                                                        Set Priority
                                                    </Dropdown.Item>
                                                </>
                                            }
                                            {
                                                info.row.original.book_by !== null &&
                                                checkPermission(profile?.permissions, 'check payment booth') &&
                                                <Dropdown.Item
                                                    onClick={async () => {
                                                        setLoadingVendor(true);
                                                        setModal('check_payment')
                                                        setBoothNumber(info.row.original.number)
                                                        const reqCheckPayment = await checkPayment(info.row.original.id);
                                                        if (reqCheckPayment.success) {
                                                            setLoadingVendor(false);
                                                        }
                                                    }}
                                                >
                                                    Check Payment
                                                </Dropdown.Item>
                                            }
                                        </Dropdown>
                                        :
                                        <p className="text-moregray">No Action</p>
                                }
                            </div>
                        </>
                    )
                }
            })
        ], []
    )

    useEffect(() => {
        getCategoryBooth(params.event_id);
    }, []);

    const handleSetPriority = async () => {
        const FD = new FormData();
        FD.append('vendor_id', vendorId);
        FD.append('booth_id', boothId);

        const response = await setPriorityVendor(FD);
        if (response.success) {
            toast.success('Success change vendor priority');
        }
    }

    const handleResetPriority = async () => {
        const FD = new FormData();
        FD.append('booth_id', boothId);

        const response = await resetPriority(FD);
        if (response.success) {
            toast.success('Success reset vendor priority');
        }
    }

    const handleDeleteBooth = async () => {
        const reqDel = await deleteBooth();

        if (reqDel === 'OK') {
            toast.success('Success delete booth');
        }
    }

    const handleDeleteCategoryBooth = async () => {
        const reqDelete = await deleteBoothCategory(categoryBoothId);

        if (reqDelete.status === 200) {
            setPopup(undefined)
            toast.success('Success delete booth category');
        }
    }

    const handleLuckySpin = async () => {
        setLoadingVendor(true);
        const listbooth = booth.filter(item => item.is_active === true);
        if (listbooth.length === 0) {
            toast.info('There is no booth available on this category');
            return false;
        }
        setModal('lucky_spin');
        const reqListVendor = await getVendorByBooth(listbooth[0].id, true);
        if (reqListVendor.success) {
            setLoadingVendor(false);
        }
    }

    const handleDownloadExcelForm = async (dataVendor) => {
        return API.get(`form/answer/export/excel/${params.event_id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/zip',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },

        }).then(res => {
            let tempLink = document.createElement('a');
            var data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            var csvURL = window.URL.createObjectURL(data);
            tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', `${eventDetail.name} form recap.zip`);
            tempLink.click();
        }).catch(err => {
            handleError(err.response);
        })

    }

    return (
        <main className="px-5 mt-5 mb-5">
            {
                categoryBooth.length > 0 ?
                    <>
                        <div className="flex justify-center mb-5">
                            {
                                categoryBooth.map(booth => {
                                    if (booth.is_active) {
                                        return (

                                            <Image
                                                path={booth.img_url}
                                                style={{ width: 500, height: 200 }}
                                                key={booth.img_url}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className=" relative">
                            <ul className=" w-full flex gap-0 z-10">
                                {
                                    categoryBooth.map(boothItem => {
                                        if (boothItem.is_active) {
                                            return (
                                                <li
                                                    key={boothItem.name}
                                                    className=" w-[150px] cursor-pointer relative bg-white px-2 py-2 text-default capitalize flex justify-between items-center">
                                                    <a href="#">{boothItem.name.length > 14 ? boothItem.name.substring(0, 14) + '...' : boothItem.name}</a>
                                                    {
                                                        profile?.permissions !== undefined ?
                                                            <>
                                                                {
                                                                    checkPermission(profile?.permissions, 'update category booth') ||
                                                                        checkPermission(profile?.permissions, 'delete category booth') ?
                                                                        <Dropdown
                                                                            renderTrigger={() => <button className='flex justify-center'><ThreeDots rotate={90} /></button>}
                                                                        >
                                                                            {
                                                                                checkPermission(profile?.permissions, 'update category booth') &&
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        setTypeActionCategory('edit')
                                                                                        setCategoryBoothId(boothItem.id);
                                                                                        setCategoryName(boothItem.name);
                                                                                        setModal('add_booth_category')
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Dropdown.Item>
                                                                            }
                                                                            {
                                                                                checkPermission(profile?.permissions, 'delete category booth') &&
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        setCategoryBoothId(boothItem.id);
                                                                                        setCategoryName(boothItem.name);
                                                                                        setPopup('delete_category_booth')
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </Dropdown.Item>
                                                                            }
                                                                        </Dropdown>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </li>
                                            )
                                        }

                                        return (
                                            <li
                                                key={boothItem.name}
                                                className=" w-[150px] cursor-pointer bg-appgray relative capitalize py-2 px-2 flex justify-between items-center">
                                                <a href="#" style={{ width: 'inherit' }} onClick={async () => {
                                                    changeBoothCategory(boothItem.id)
                                                    getBoothByCategory(boothItem.id)
                                                }}>{boothItem.name.length > 14 ? boothItem.name.substring(0, 14) + '...' : boothItem.name}</a>
                                                {
                                                    profile?.permissions !== undefined ?
                                                        <>
                                                            {
                                                                checkPermission(profile?.permissions, 'update category booth') ||
                                                                    checkPermission(profile?.permissions, 'delete category booth') ?
                                                                    <Dropdown
                                                                        renderTrigger={() => <button className='flex justify-center'><ThreeDots rotate={90} /></button>}
                                                                    >
                                                                        {
                                                                            checkPermission(profile?.permissions, 'update category booth') &&
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    setTypeActionCategory('edit')
                                                                                    setCategoryBoothId(boothItem.id);
                                                                                    setCategoryName(boothItem.name);
                                                                                    setModal('add_booth_category')
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Dropdown.Item>
                                                                        }
                                                                        {
                                                                            checkPermission(profile?.permissions, 'delete category booth') &&
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    setPopup('delete_category_booth')
                                                                                    setCategoryBoothId(boothItem.id)
                                                                                    setCategoryName(boothItem.name)
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </Dropdown.Item>
                                                                        }
                                                                    </Dropdown>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </li>
                                        )
                                    })
                                }
                                {
                                    profile?.permissions !== undefined ?
                                        <>
                                            {
                                                checkPermission(profile?.permissions, 'create category booth') &&
                                                <li className="flex items-center ml-3" >
                                                    <button onClick={() => {
                                                        setModal('add_booth_category')
                                                        setCategoryName(null)
                                                        setTypeActionCategory('add')
                                                    }}>
                                                        <Tooltip content="Add Category">
                                                            <Add />
                                                        </Tooltip>
                                                    </button>
                                                </li>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </ul>
                        </div>
                        <div className="bg-white rounded-b-lg p-5">
                            <div className="text-right space-x-3 mb-3">
                                {
                                    profile?.permissions !== undefined ?
                                        <>
                                            {
                                                checkPermission(profile?.permissions, 'download form') &&
                                                <button className="btn" onClick={() => handleDownloadExcelForm()}>Download Excel Form</button>
                                            }
                                            {
                                                checkPermission(profile?.permissions, 'lucky spin') &&
                                                <button className="btn" onClick={() => handleLuckySpin()}>Lucky Spin</button>
                                            }
                                            {
                                                checkPermission(profile?.permissions, 'create booth') &&
                                                <button className="btn" onClick={() => {
                                                    setModal('add_booth')
                                                    setType('add');
                                                }}>Add Booth</button>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                            <TableComponent
                                data={booth}
                                columns={boothColumn}
                            />
                        </div>
                    </>
                    :
                    <div className="w-full flex justify-center items-center flex-col">
                        <img src={Ilustration} />
                        {
                            profile?.permissions !== undefined ?
                                <>
                                    {
                                        checkPermission(profile?.permissions, 'create category booth') &&
                                        <button className="btn mt-10" onClick={() => {
                                            setModal('add_booth_category')
                                            setCategoryName(null)
                                            setTypeActionCategory('add')
                                        }}>Add Categories</button>
                                    }
                                </>
                                :
                                <></>

                        }
                    </div>
            }
            <ModalBanner
                choosedModal={'add_booth_category'}
                title={typeActionCategory === 'add' ? 'Add Booth Category' : 'Edit Booth category'}
                textButton="Submit"
                actionClearForm={clearCategoryBoothForm}
                idBtnSubmit="btn_add_booth_category"
            >
                <BoothCategoryForm type={typeActionCategory} />
            </ModalBanner>
            <ModalBanner
                choosedModal={'list_vendor'}
                title={`Vendor List (${boothNumber})`}
                textButton={null}
                actionClearForm={clearCategoryBoothForm}
                useModalFooter={false}
            >
                {
                    loadingVendor ?
                        <Loading />
                        :
                        <VendorList vendors={vendor} />
                }
            </ModalBanner>
            <ModalDetailPayment loadingVendor={loadingVendor} />
            <ModalBanner
                choosedModal={'list_priority'}
                title={'Set Priority'}
                textButton={
                    profile?.permissions !== undefined ?
                        checkPermission(profile?.permissions, 'reset priority') ? 'Reset' : null
                        :
                        null
                }
                actionClearForm={clearCategoryBoothForm}
                idBtnSubmit={'reset_priority_button'}
                useModalFooter={
                    profile?.permissions !== undefined ?
                        checkPermission(profile?.permissions, 'reset priority') ? true : false
                        :
                        false

                }
            >
                {
                    loadingVendor ?
                        <Loading />
                        :
                        vendor.length > 0 ?
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setPopup('reset_priority');
                            }}>
                                <ul className="divide-y divide-[#E5E5E5]">
                                    {
                                        vendor.map(item => {
                                            return (
                                                <div className="flex justify-between py-3" key={item.id}>
                                                    <h1 className="capitalize">{item.vendor_name}</h1>
                                                    <li className=' flex items-center' key={item.id}>
                                                        <label className=' tracking-[0.48px]'>
                                                            <input
                                                                value={item.id}
                                                                onChange={(e) => {
                                                                    setPopup('set_priority');
                                                                    setVendorId(item.vendor_id);
                                                                    setVendorName(item.vendor_name);
                                                                    if (e.target.checked) {
                                                                        setPopUpText('set');
                                                                    } else {
                                                                        setPopUpText('unset');
                                                                    }
                                                                }}
                                                                checked={item.is_priority === 1 ? true : false}
                                                                required={true}
                                                                className='opacity-0 !m-0 peer'
                                                                type="radio"
                                                            />
                                                            <span className='  peer-checked:border peer-checked:border-default peer-checked:after:opacity-100 -left-2 top-[6px] cursor-pointer w-[17px] h-[17px] border border-appblack inline-block rounded-[50%] relative after:content[""] after:w-[10px] after:h-[10px] after:bg-default after:absolute after:rounded-[50%] after:top-1/2 after:left-1/2 after:opacity-0 after:translate-x-[-50%] after:translate-y-[-50%]'></span>
                                                        </label>
                                                    </li>
                                                </div>
                                            )
                                        })
                                    }
                                </ul>
                                <button type="submit" id="reset_priority_button" hidden></button>
                            </form>
                            :
                            <div className="flex justify-center">
                                <img src={Ilustration} width={200} alt="" srcset="" />
                            </div>
                }
            </ModalBanner>
            <ModalBanner
                choosedModal={'add_booth'}
                title={type === 'add' ? 'Add Booth' : 'Edit Booth'}
                textButton="Submit"
                actionClearForm={clearBoothForm}
                idBtnSubmit="add_booth"
            >
                <BoothForm type={type} />
            </ModalBanner>
            <Popup
                action={handleDeleteCategoryBooth}
                icon={<Trash width={35} height={35} />}
                type="delete_category_booth"
                textBtn="Delete"
            >
                Are you sure you want to delete category booth <strong>#{categoryName}</strong>?
                <br />
                This category booth will permanently delete
            </Popup>
            <Popup
                action={handleDeleteBooth}
                icon={<Trash width={35} height={35} />}
                type="delete_booth"
                textBtn="Delete"
            >
                Are you sure you want to delete booth <strong>#{boothNumber}</strong>?
                <br />
                This booth will permanently delete
            </Popup>
            <Popup
                action={handleSetPriority}
                icon={<Checklist color='#F77C32' height={35} width={35} />}
                type="set_priority"
                textBtn="Proceed"
            >
                Are you sure want to {popUpText} vendor <strong>#{vendorName}</strong> as priority?
            </Popup>
            <Popup
                action={handleResetPriority}
                icon={<Checklist color='#F77C32' height={35} width={35} />}
                type="reset_priority"
                textBtn="Proceed"
            >
                Are you sure want to reset priority in this booth?
            </Popup>
            <ModalLuckySpin isLoading={loadingVendor} actionChangeLoading={setLoadingVendor} />
            <Modal show={modal === 'img_booth'} onClose={() => setModal(undefined)}>
                <Modal.Header>Image Booth <strong>({boothNumber})</strong></Modal.Header>
                <Modal.Body>
                    <Image
                        path={imgUrl}
                        style={{
                            width: '100%',
                            height: 300
                        }}
                    />
                </Modal.Body>
            </Modal>
        </main>
    );
}

export default EventBooth;