import React, { Fragment, useContext, useRef } from "react";
import ThreeDots from "./icons/ThreeDots";
import { Dropdown } from "flowbite-react";
import ModalContext from "context/ModalContext";
import Popup from "./Modal/Popup";
import Xicon from "./icons/Xicon";
import Checklist from "./icons/Checklist";
import VendorContext from "context/VendorContext";
import Input from "./Input";
import Textarea from "./Textarea";
import Ilustration from '../assets/images/event-ilustration.svg';
import BoothContext from "context/BoothContext";
import SelectInput from 'components/atoms/SelectInputLabel'
import { Badge } from "flowbite-react";
import { STATUS_BOOKING } from "helpers/StatusBooking";
import TextAreaWithLabel from "./atoms/TextAreaWithLabel";
import { toast } from "react-toastify";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function VendorList({ vendors }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { setPopup, setModal } = useContext(ModalContext);
    const { vendorName, setVendorName, vendorId, setVendorId } = useContext(VendorContext);
    const { boothId, categoryBooth, categoryBoothId, setCategoryBoothId, getProposeBooth, activeBooth, proposeBooth, proposeReason, setProposeReason, bookingBoothId, setBookingBoothId, setProposeBoothId, proposeBoothId, rejectedBoothId, acceptVendor, rejectVendor, proposeBoothAction, booth } = useContext(BoothContext);
    const target = useRef(null);

    const makeCollapse = (id) => {
        let colTarget = document.getElementById(id);

        if (colTarget.classList.contains('max-h-0')) {
            colTarget.classList.remove('max-h-0');
            colTarget.classList.add('max-h-[385px]');
        } else {
            colTarget.classList.remove('max-h-[385px]');
            colTarget.classList.add('max-h-0');
        }
    }

    const handleAcceptVendor = async () => {
        const FD = new FormData();

        FD.append('booth_id', boothId);
        FD.append('vendor_id', vendorId);

        const reqAcceptVendor = await acceptVendor(FD);
        if (reqAcceptVendor === 'OK') {
            toast.success("Success approve vendor");
            setModal(undefined);
            setPopup(undefined);
        }
    }

    const handleRejectVendor = async () => {
        const FD = new FormData();

        FD.append('booth_id', boothId);
        FD.append('vendor_id', vendorId);

        const reqRejectVendor = await rejectVendor(FD);
        if (reqRejectVendor === 'OK') {
            toast.success('Success reject vendor');
            setModal(undefined);
            setPopup(undefined);
        }
    }

    const proceedPropose = async () => {
        const FD = new FormData();

        FD.append('booth_id', proposeBoothId);
        FD.append('vendor_id', vendorId);
        FD.append('booking_booth_id', bookingBoothId);
        FD.append('description', proposeReason);
        FD.append('booth_id_reject', rejectedBoothId);

        const proposeBooth = await proposeBoothAction(FD);

        if (proposeBooth.status === 200) {
            if (proposeBooth.data.success) {
                toast.success('Success propose booth');
                setModal(undefined);
                setPopup(undefined);
            }
        }
    }

    const handleDownloadForm = async (dataVendor) => {
        const FD = new FormData();
        FD.append('booking_booth_id', dataVendor.booking_booth_id);

        return API.post(`form/answer/export`, FD, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },

        }).then(res => {
            let tempLink = document.createElement('a');
            var data = new Blob([res.data], { type: 'text/pdf' });
            var csvURL = window.URL.createObjectURL(data);
            tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', `form-answer-${dataVendor.brand_name}.pdf`);
            tempLink.click();
        }).catch(err => {
            handleError(err.response);
        })

    }

    const categoryBoothActive = categoryBooth.find(item => item.is_active === true);

    return (
        <>
            <ul className="divide-y divide-slate-200" ref={target}>
                {
                    vendors.length > 0 ?
                        vendors.map(vendor => {
                            return (
                                <>
                                    <li className="py-3">
                                        <div className="flex justify-between items-center ">
                                            <div className="flex items-center" style={{ width: '-webkit-fill-available' }}>
                                                <div>
                                                    <h1 className="mr-3 capitalize font-semibold">{vendor.vendor_name}</h1>
                                                    <p className="mr-3 capitalize">({vendor.brand_name})</p>
                                                </div>
                                                <Badge className={` ml-auto mr-5 capitalize ${STATUS_BOOKING[vendor.status]}`}>{vendor.status.replace('_', ' ')}</Badge>
                                            </div>
                                            {
                                                vendor.status === 'paid' &&
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'download form answer') ?
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                <Dropdown.Item
                                                                    onClick={() => handleDownloadForm(vendor)}
                                                                >
                                                                    Download Form Answer
                                                                </Dropdown.Item>
                                                            </Dropdown>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                            }
                                            {
                                                vendor.status === 'waiting_payment' &&
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'download form answer') ||
                                                            checkPermission(profile?.permissions, 'reject booth') ?
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                {
                                                                    checkPermission(profile?.permissions, 'reject booth') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setVendorId(vendor.vendor_id);
                                                                            setPopup('reject_vendor');
                                                                            setVendorName(vendor.vendor_name)
                                                                        }}
                                                                    >
                                                                        Reject
                                                                    </Dropdown.Item>
                                                                }
                                                                {
                                                                    checkPermission(profile?.permissions, 'download form answer') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => handleDownloadForm(vendor)}
                                                                    >
                                                                        Download Form Answer
                                                                    </Dropdown.Item>
                                                                }

                                                            </Dropdown>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                            }
                                            {
                                                vendor.status === 'reject' &&
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'download form answer') ||
                                                            checkPermission(profile?.permissions, 'propose booth') ?
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                {
                                                                    checkPermission(profile?.permissions, 'propose booth') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            const FD = new FormData();
                                                                            FD.append('category_booth_id', categoryBoothActive.id);
                                                                            FD.append('vendor_id', vendor.vendor_id);
                                                                            FD.append('booking_booth_id', vendor.booking_booth_id);
                                                                            makeCollapse(vendor.vendor_id);
                                                                            setVendorId(vendor.vendor_id)
                                                                            getProposeBooth(FD);
                                                                            setVendorName(vendor.vendor_name);
                                                                            setBookingBoothId(vendor.booking_booth_id);
                                                                        }}
                                                                    >
                                                                        Propose
                                                                    </Dropdown.Item>
                                                                }
                                                                {
                                                                    checkPermission(profile?.permissions, 'download form answer') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => handleDownloadForm(vendor)}
                                                                    >
                                                                        Download Form Answer
                                                                    </Dropdown.Item>
                                                                }
                                                            </Dropdown>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                            }
                                            {
                                                vendor.status === 'propose' &&
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'download form answer') &&
                                                        <Dropdown
                                                            renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                        >
                                                            <Dropdown.Item
                                                                onClick={() => handleDownloadForm(vendor)}
                                                            >
                                                                Download Form Answer
                                                            </Dropdown.Item>
                                                        </Dropdown>
                                                    }
                                                </>
                                            }
                                            {
                                                vendor.status === 'pending' &&
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'download form answer') ||
                                                            checkPermission(profile?.permissions, 'approve booth') ||
                                                            checkPermission(profile?.permissions, 'reject booth') ||
                                                            checkPermission(profile?.permissions, 'propose booth') ?
                                                            <Dropdown
                                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                            >
                                                                {
                                                                    checkPermission(profile?.permissions, 'approve booth') &&
                                                                    <Dropdown.Item
                                                                        onClick={(info) => {
                                                                            setVendorId(vendor.vendor_id);
                                                                            setPopup('accept_vendor');
                                                                            setVendorName(vendor.vendor_name)
                                                                        }}
                                                                    >
                                                                        Approve
                                                                    </Dropdown.Item>
                                                                }
                                                                {
                                                                    checkPermission(profile?.permissions, 'reject booth') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setVendorId(vendor.vendor_id);
                                                                            setPopup('reject_vendor');
                                                                            setVendorName(vendor.vendor_name)
                                                                        }}
                                                                    >
                                                                        Reject
                                                                    </Dropdown.Item>
                                                                }
                                                                {
                                                                    checkPermission(profile?.permissions, 'propose booth') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            const FD = new FormData();
                                                                            FD.append('category_booth_id', categoryBoothActive.id);
                                                                            FD.append('vendor_id', vendor.vendor_id);
                                                                            FD.append('booking_booth_id', vendor.booking_booth_id)
                                                                            makeCollapse(vendor.vendor_id);
                                                                            setVendorId(vendor.vendor_id)
                                                                            getProposeBooth(FD);
                                                                            setVendorName(vendor.vendor_name);
                                                                            setBookingBoothId(vendor.booking_booth_id);
                                                                        }}
                                                                    >
                                                                        Propose
                                                                    </Dropdown.Item>
                                                                }
                                                                {
                                                                    checkPermission(profile?.permissions, 'download form answer') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => handleDownloadForm(vendor)}
                                                                    >
                                                                        Download Form Answer
                                                                    </Dropdown.Item>
                                                                }
                                                            </Dropdown>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div id={`${vendor.vendor_id}`} className="transition-[max-height] duration-300 ease-in-out max-h-0 overflow-hidden">
                                            <form
                                                className="p-3 bg-background my-2 rounded"
                                                onSubmit={(e) => {
                                                e.preventDefault();
                                                setPopup('propose_booth')
                                            }}>
                                                <SelectInput
                                                    required
                                                    label="Booth Number"
                                                    options={proposeBooth}
                                                    value={proposeBoothId}
                                                    onChange={(e) => setProposeBoothId(e.target.value)}
                                                    classInput={` !text-base !font-medium !tracking-[0.48px] !p-0 !px-5 valid:!empty-form ${proposeBoothId === null ? 'valid:!empty-form' : 'valid:valid-form'}`}
                                                />
                                                <TextAreaWithLabel
                                                    label={'Reason'}
                                                    required={true}
                                                    onChange={(e) => setProposeReason(e.target.value)}
                                                    classInput={` py-6 !px-5 ${proposeReason === null ? 'valid:!empty-form' : 'valid:valid-form'}`}
                                                />
                                                <div className="flex justify-center space-x-3 mt-5">
                                                    <button type="sumbit" className="btn w-32">Send Propose</button>
                                                    <button type="button" className="btn-cancel w-32" onClick={() => {
                                                        makeCollapse(vendor.vendor_id);
                                                        setProposeBoothId(null);
                                                        setProposeReason(null);
                                                    }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </>
                            )
                        })
                        :
                        <div className="flex justify-center">
                            <img src={Ilustration} width={200} alt="" srcset="" />
                        </div>
                }
            </ul>
            <Popup
                action={() => handleAcceptVendor()}
                icon={<Checklist color={'#10B986'} width={35} height={45} />}
                type="accept_vendor"
                textBtn="Accept"
            >
                Are you sure you want to approve vendor <strong>#{vendorName}</strong>?
            </Popup>
            <Popup
                action={() => handleRejectVendor()}
                icon={<Xicon color={'#B91010'} width={40} height={40} />}
                type="reject_vendor"
                textBtn="Accept"
            >
                If you reject vendor <strong>#{vendorName}</strong>, you still can propose another booth to them.
                you sure want to reject?
            </Popup>
            <Popup
                action={() => proceedPropose()}
                icon={null}
                type="propose_booth"
                textBtn="Proceed"
            >
                Are you sure want to propose this booth to vendor <strong>#{vendorName}</strong>
            </Popup>
        </>
    );
}

export default VendorList;