import Input from "../Input";
import { useEvent } from "../../context/EventContext";
import Xicon from "components/icons/Xicon";
import UploadIcon from "components/icons/UploadIcon";
import Textarea from "components/Textarea";
import YTPreview from "components/YtPreview";
import { useContext } from "react";
import ModalContext from "context/ModalContext";
import NewEventContext from "context/NewEventContext";
import Popup from "components/Modal/Popup";
import Image from "components/atoms/Image";

function EventForm({ type, action }) {
    const { setModal, setPopup } = useContext(ModalContext);
    const { setOldImages, setOldFiles, oldImages, oldFiles, eventId, title, description, errorYtLink, ytLink, errorBanner, errorImages, handleChangeGuideLineFiles, createEvent, editEvent, setYtLink, setYtLoading, handleChangeBanner, startDate, endDate, images, banner, venue, tnc, setTitle, setDescription, setStartDate, setEndDate, setTnc, setVenue, setBanner, setImages, handleChangeImages, handleRemoveImagesItem, handleChangeYtLink, handleRemoveFilesItem, cleanUpEventForm, guideLineFiles, setGuideLineFiles } = useContext(NewEventContext);

    const onCreateOrEditEvent = (e) => {
        e.preventDefault();

        console.log(oldFiles);

        console.log(oldImages);

        setPopup('create_event');
    }

    const proceedAddEvent = () => {
        const FD = new FormData();

        FD.append('name', title);
        FD.append('description', description);
        FD.append('start_date', startDate);
        FD.append('end_date', endDate);
        FD.append('tnc', tnc);
        FD.append('location', venue);

        if (banner !== null) {
            FD.append('banner_image', banner);
        }

        if (images.length > 0) {
            images.map((image, index) => {
                FD.append(`image[${index}]`, image);
            });
        }

        if (guideLineFiles.length > 0) {
            guideLineFiles.map((image, index) => {
                FD.append(`file[${index}]`, image);
            });
        }

        if (ytLink !== null) {
            FD.append('youtube_url', ytLink);
        }

        if (eventId !== null) {
            FD.append('event_id', eventId);
        }

        if (type === 'add') {
            action.mutate(FD)
            setModal(undefined);
            setPopup(undefined);
            cleanUpEventForm();
        } else {
            if (oldFiles.length > 0) {
                oldFiles.map((file, index) => {
                    FD.append(`list_id_file[${index}]`, file.file_id);
                })
            }

            if (oldImages.length > 0) {
                oldImages.map((file, index) => {
                    FD.append(`list_id_image[${index}]`, file.file_id);
                })
            }

            action.mutate(FD);
            setModal(undefined);
            setPopup(undefined);
            cleanUpEventForm();
        }
    }

    let textBanner = '';
    let bannerClass = '';
    let imageClass = '';
    let imageText = '';
    let textFiles = '';
    let filesClass = '';

    if (errorBanner != null) {
        textBanner = errorBanner;
        bannerClass = `error-form`;
    } else if (banner != null) {
        textBanner = banner.name;
        bannerClass = `valid-form`;
    } else {
        textBanner = 'No image choosen';
    }

    if (errorImages != null) {
        imageClass = 'error-form';
        imageText = errorImages;
    } else if (images.length > 0) {
        imageText = images.length > 1 ? images.length + ' files' : images.length + ' file';
        imageClass = 'valid-form';
    } else {
        imageText = 'No file choosen';
    }

    if (guideLineFiles.length > 0) {
        textFiles = guideLineFiles.length > 1 ? guideLineFiles.length + ' files' : guideLineFiles.length + ' file'
        filesClass = 'valid-form'
    } else {
        textFiles = 'No file choosen';
    }

    const onChangeYtLink = (url) => {
        setYtLink(url);
        setYtLoading(true);
    }

    return (
        <>
            <form onSubmit={onCreateOrEditEvent}>
                <div className="space-y-3">
                    <div>
                        <label htmlFor="" className="after:content-['*'] after:ml-0.5 after:text-red-500">Event Name</label>
                        <Input
                            type="text"
                            isRequired={true}
                            action={setTitle}
                            value={title}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className="after:content-['*'] after:ml-0.5 after:text-red-500">Description</label>
                        <Textarea
                            value={description}
                            action={setDescription}
                            isRequired={true}
                        />
                    </div>
                    <div className="columns-2">
                        <div>
                            <label htmlFor="" className="after:content-['*'] after:ml-0.5 after:text-red-500">Start Date</label>
                            <Input
                                type="date"
                                isRequired={true}
                                action={setStartDate}
                                value={startDate}
                            />
                        </div>
                        <div>
                            <label htmlFor="" className="after:content-['*'] after:ml-0.5 after:text-red-500">End Date</label>
                            <Input
                                type="date"
                                isRequired={true}
                                action={setEndDate}
                                value={endDate}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="" className="after:content-['*'] after:ml-0.5 after:text-red-500">Term and Conditions</label>
                        <Textarea
                            value={tnc}
                            action={setTnc}
                            isRequired={true}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className="after:content-['*'] after:ml-0.5 after:text-red-500">Venue</label>
                        <Input
                            type="text"
                            isRequired={true}
                            action={setVenue}
                            value={venue}
                        />
                    </div>
                    <div>
                        <label htmlFor="event_banner" className={`${type === 'add' ? `after:content-['*'] after:ml-0.5 after:text-red-500` : ``}`}>Upload Banner</label>
                        <div
                            className={`flex mt-2 items-center justify-between form-control bg-semigray cursor-pointer ${bannerClass}`}
                            onClick={() => document.getElementById('event_banner').click()}
                        >
                            <p>
                                {
                                    textBanner
                                }
                            </p>
                            <input
                                id="event_banner"
                                onChange={handleChangeBanner}
                                type='file'
                                required={type === 'add' ? true : false}
                                multiple={false}
                                className="outline-none border-none ml-0 h-7 p-0 w-11/12 bg-inherit focus:ring-0 peer"
                                accept="image/*"
                                hidden
                            />
                            <label onClick={() => document.getElementById('event_banner').click()}>
                                <UploadIcon />
                            </label>
                        </div>
                        <p className="text-red-500 text-xs">*Minimal image dimension is 1200x450</p>
                    </div>
                    <div>
                        <label htmlFor="event_image" className={`${type === 'add' ? `after:content-['*'] after:ml-0.5 after:text-red-500` : ``}`}>Upload Image</label>
                        <div
                            className={`flex mt-2 items-center justify-between form-control bg-semigray cursor-pointer ${imageClass}`}
                            onClick={() => document.getElementById('event_image').click()}
                        >
                            <p>
                                {
                                    imageText
                                }
                            </p>
                            <input
                                id="event_image"
                                onChange={handleChangeImages}
                                type='file'
                                required={type === 'add' ? true : false}
                                multiple={true}
                                className="outline-none border-none ml-0 h-7 p-0 w-11/12 bg-inherit focus:ring-0 peer"
                                accept="image/*"
                                hidden
                            />
                            <label>
                                <UploadIcon />
                            </label>
                        </div>
                        <p className="text-red-500 text-xs">*Maximal image inputed is 5</p>
                        <div className="mx-14 my-5 space-y-5">
                            {
                                images.map(image => {
                                    return <div className="flex justify-between items-center">
                                        <p>
                                            {
                                                image.name
                                            }
                                        </p>
                                        <div
                                            className="w-8 h-8 bg-moregray rounded-full flex justify-center items-center cursor-pointer"
                                            onClick={() => handleRemoveImagesItem(image)}
                                        >
                                            <Xicon color={'#FFF'} width={15} height={15} />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="mx-14 my-5 space-y-7">
                            {
                                type === 'edit' &&
                                <>
                                    <p className="text-red-500 text-xs">List Uploaded Images</p>
                                    {
                                        oldImages.map(imag => {
                                            return (
                                                <div className="relative">
                                                    <div
                                                        className="absolute -right-4 -top-4 w-8 h-8 bg-moregray rounded-full flex justify-center items-center cursor-pointer"
                                                        onClick={() => {
                                                            setOldImages(currImage => {
                                                                return currImage.filter(image => image.id !== imag.id);
                                                            })
                                                        }}
                                                    >
                                                        <Xicon color={'#FFF'} width={15} height={15} />
                                                    </div>
                                                    <Image
                                                        path={imag.img_url}
                                                        style={{ width: 500, height: 200 }}
                                                        key={imag.img_url}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div>
                        <label htmlFor="guideLineFiles">Guideline & Procedural File</label>
                        <div
                            className={`flex mt-2 items-center justify-between form-control bg-semigray cursor-pointer ${filesClass}`}
                            onClick={() => document.getElementById('guideLineFiles').click()}
                        >
                            <p>
                                {
                                    textFiles
                                }
                            </p>
                            <input
                                id="guideLineFiles"
                                onChange={handleChangeGuideLineFiles}
                                type='file'
                                required={false}
                                multiple={true}
                                className="outline-none border-none ml-0 h-7 p-0 w-11/12 bg-inherit focus:ring-0 peer"
                                accept=".pdf"
                                hidden
                            />
                            <label>
                                <UploadIcon />
                            </label>
                        </div>
                        <div className="mx-14 my-5 space-y-5">
                            {
                                guideLineFiles.map(image => {
                                    return <div className="flex justify-between items-center">
                                        <p>
                                            {
                                                image.name
                                            }
                                        </p>
                                        <div
                                            className="w-8 h-8 bg-moregray rounded-full flex justify-center items-center cursor-pointer"
                                            onClick={() => handleRemoveFilesItem(image)}
                                        >
                                            <Xicon color={'#FFF'} width={15} height={15} />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="mx-14 my-5 space-y-5">
                            {
                                type === 'edit' &&
                                <>
                                    <p className="text-xs text-red-500">List Uploaded Files</p>
                                    {
                                        oldFiles.map(file => {
                                            return <div className="flex justify-between items-center">
                                                <p>
                                                    {
                                                        file.file_name
                                                    }
                                                </p>
                                                <div
                                                    className="w-8 h-8 bg-moregray rounded-full flex justify-center items-center cursor-pointer"
                                                    onClick={() => {
                                                        setOldFiles(currFiles => {
                                                            return currFiles.filter(fileItem => fileItem.id !== file.id)
                                                        })
                                                    }}
                                                >
                                                    <Xicon color={'#FFF'} width={15} height={15} />
                                                </div>
                                            </div>
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div>
                        <label htmlFor="">Video Link</label>
                        <Input
                            type="url"
                            isRequired={false}
                            action={onChangeYtLink}
                            value={ytLink}
                        />
                        {
                            errorYtLink !== null ? <p className="text-red-500 text-xs">{errorYtLink}</p> : <></>
                        }
                        {
                            <YTPreview />
                        }
                    </div>
                </div>
                <button type="submit" hidden id="btn_event"></button>
            </form>
            <Popup
                action={proceedAddEvent}
                icon={null}
                type="create_event"
                textBtn="Submit"
            >
                {
                    type === 'add' ? 'Are you sure you want to proceed create event?' : 'Are you sure you want to proceed edit event?'
                }
            </Popup>
        </>
    );
}

export default EventForm;