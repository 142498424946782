import EventDetailLayout from 'components/layouts/EventDetailLayout';
import WrapperAuth from 'components/layouts/WrapperAuth';
import BoothState from 'context/BoothState';
import EventState from 'context/EventState';
import MemberState from 'context/MemberState';
import ModalState from 'context/ModalState';
import VendorState from 'context/VendorState';
import EventBooth from 'pages/EventBooth';
import EventComplain from 'pages/EventComplain';
import EventDetail from 'pages/EventDetail';
import EventEquipment from 'pages/EventEquipment';
import EventForm from 'pages/EventForm';
import EventInvoice from 'pages/EventInvoice';
import EventMembers from 'pages/EventMembers';
import EventPurchasing from 'pages/EventPurchasing';
import EventQuestions from 'pages/EventQuestions';
import EventSettings from 'pages/EventSetting';
import EventVendors from 'pages/EventVendors';
import EventVouchers from 'pages/EventVouchers';
import ForgotPassword from 'pages/ForgotPassword';
import FormRegistrationBooth from 'pages/FormRegistrationBooth';
import History from 'pages/History';
import Login from 'pages/Login';
import MemberList from 'pages/MemberList';
import NewGlobalHome from 'pages/NewGlobalHome';
import NotFound from 'pages/NotFound';
import Profile from 'pages/Profile';
import { createBrowserRouter } from 'react-router-dom';
import InventoryState from 'context/InventoryState';
import PurchaseState from 'context/PurchaseState';
import CreatePurchase from 'pages/CreatePurchase';
import ComplainState from 'context/ComplainState';
import EditPurchase from 'pages/EditPurchase';
import DownloadInvoice from 'pages/DownloadInvoice';
import EventPurchasingOut from 'pages/EventPurchasingOut';
import CreatePurchaseOut from 'pages/CreatePurchaseOut';
import AdminPermissionPage from 'pages/AdminPermissionPage';
import MemberPermissionPage from 'pages/MemberPermissionPage';
import ResetPassowrd from 'pages/ResetPassword';
import CustomNotification from 'pages/CustomNotification';
import Settings from 'pages/Settings';
import TenantGlobalPage from 'pages/TenantGlobalPage';

export const MainsStack = {
    login: '/',
    formRegistrationBooth: "/form-registration-booth/:access_token/:email/:refresh_token/:event_id",
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password/:token',
    admin: '/admin',
    adminCustomNotification: '/admin/custom-notification',
    profile: '/admin/profile',
    memberList: '/admin/member-list',
    history: '/admin/history',
    adminEvent: '/admin/event/:event_id',
    eventHome: '/admin/event/:event_id/home',
    eventBooth: '/admin/event/:event_id/booth',
    eventQuestions: '/admin/event/:event_id/questions/:form_id',
    adminPermissions: '/admin/permission/:admin_id',
    eventForm: '/admin/event/:event_id/form',
    eventVendors: '/admin/event/:event_id/vendors',
    eventEquipment: '/admin/event/:event_id/inventory',
    eventPurchase: '/admin/event/:event_id/purchase',
    createPurchase: '/admin/event/:event_id/create-purchase',
    createPurchaseOut: '/admin/event/:event_id/create-purchase-out',
    editPurchase: '/admin/event/:event_id/edit-purchase/:purchase_id',
    eventInvoice: '/admin/event/:event_id/invoice',
    eventMembers: '/admin/event/:event_id/members',
    eventVouchers: '/admin/event/:event_id/vouchers',
    eventComplain: '/admin/event/:event_id/complain',
    eventSettings: '/admin/event/:event_id/settings',
    eventPurchaseOut: '/admin/event/:event_id/purchase-out',
    memberPermissions: '/admin/event/:event_id/member-permission/:member_id',
    invoiceDownload: `/admin/event/:event_id/invoice/download/:invoice_id`,
    settings: '/admin/settings',
    notFound: '*',
    tenantGlobal: '/admin/tenant-list',
}

export const ROUTER = createBrowserRouter([
    {
        path: MainsStack.login,
        element: <Login />,
    },
    {
        path: MainsStack.formRegistrationBooth,
        element: <FormRegistrationBooth />,
    },
    {
        path: MainsStack.resetPassword,
        element: <ResetPassowrd />
    },
    {
        path: MainsStack.forgotPassword,
        element: <ForgotPassword />,
    },
    {
        path: MainsStack.admin,
        element: (
            <ModalState>
                <WrapperAuth />
            </ModalState>
        ),
        children: [
            {
                index: true,
                element: (
                    <EventState>
                        <MemberState>
                            <NewGlobalHome />
                        </MemberState>
                    </EventState>
                ),
            },
            {
                path: MainsStack.settings,
                element: (
                    <Settings />
                )
            },
            {
                path: MainsStack.tenantGlobal,
                element:
                    <MemberState>
                        <TenantGlobalPage />
                    </MemberState>
            },
            {
                path: MainsStack.adminCustomNotification,
                element: <CustomNotification />
            },
            {
                path: MainsStack.adminPermissions,
                element: (
                    <MemberState>
                        <AdminPermissionPage />
                    </MemberState>
                )
            },
            {
                path: MainsStack.profile,
                element: <Profile />,
            },

            {
                path: MainsStack.memberList,
                element: (
                    <MemberState>
                        <MemberList />
                    </MemberState>
                ),
            },
            {
                path: MainsStack.history,
                element: (
                    <EventState>
                        <History />
                    </EventState>
                ),
            },
            {
                path: MainsStack.adminEvent,
                element: <EventDetailLayout />,
                children: [
                    {
                        path: MainsStack.eventHome,
                        element: (
                            <MemberState>
                                <EventDetail />
                            </MemberState>
                        )
                    },
                    {
                        path: MainsStack.eventBooth,
                        element: (
                            <BoothState>
                                <VendorState>
                                    <EventBooth />
                                </VendorState>
                            </BoothState>
                        )
                    },
                    {
                        path: MainsStack.eventQuestions,
                        element: (
                            <EventQuestions />
                        )
                    },
                    {
                        path: MainsStack.eventForm,
                        element: (
                            <EventForm />
                        )
                    },
                    {
                        path: MainsStack.eventVendors,
                        element: (
                            <BoothState>
                                <VendorState>
                                    <EventVendors />
                                </VendorState>
                            </BoothState>
                        )
                    },
                    {
                        path: MainsStack.eventEquipment,
                        element: <InventoryState>
                            <EventEquipment />
                        </InventoryState>
                    },
                    {
                        path: MainsStack.eventPurchase,
                        element: <PurchaseState>
                            <EventPurchasing />
                        </PurchaseState>
                    },
                    {
                        path: MainsStack.eventPurchaseOut,
                        element: <PurchaseState>
                            <EventPurchasingOut />
                        </PurchaseState>
                    },
                    {
                        path: MainsStack.memberPermissions,
                        element: (
                            <MemberState>
                                <MemberPermissionPage />
                            </MemberState>
                        )
                    },
                    {
                        path: MainsStack.createPurchase,
                        element: (
                            <InventoryState>
                                <CreatePurchase>
                                    <EventPurchasing />
                                </CreatePurchase>
                            </InventoryState>
                        )
                    },
                    {
                        path: MainsStack.createPurchaseOut,
                        element: (
                            <MemberState>
                                <InventoryState>
                                    <CreatePurchaseOut>
                                        <EventPurchasing />
                                    </CreatePurchaseOut>
                                </InventoryState>
                            </MemberState>
                        )
                    },
                    {
                        path: MainsStack.editPurchase,
                        element: (
                            <InventoryState>
                                <EditPurchase>
                                    <EventPurchasing />
                                </EditPurchase>
                            </InventoryState>
                        )
                    },
                    {
                        path: MainsStack.eventInvoice,
                        element: <EventInvoice />
                    },
                    {
                        path: MainsStack.invoiceDownload,
                        element: (
                            <>
                                <DownloadInvoice />
                            </>
                        )
                    },
                    {
                        path: MainsStack.eventMembers,
                        element: (
                            <MemberState>
                                <EventMembers />
                            </MemberState>
                        )
                    },
                    {
                        path: MainsStack.eventVouchers,
                        element: <EventVouchers />
                    },
                    {
                        path: MainsStack.eventComplain,
                        element: (
                            <ComplainState>
                                <MemberState>
                                    <EventComplain />
                                </MemberState>
                            </ComplainState>
                        )
                    },
                    {
                        path: MainsStack.eventSettings,
                        element: (
                            <VendorState>
                                <EventSettings />
                            </VendorState>
                        )
                    }
                ]
            },
        ],
    },
    {
        path: MainsStack.notFound,
        element: <NotFound />
    }
]);