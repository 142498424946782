import { MONTHS } from "helpers/Month";
import Image from "components/atoms/Image";
import InputWithLabel from "components/atoms/InputWithLabel";
import TextAreaWithLabel from "components/atoms/TextAreaWithLabel";

function FormDetailComplain({ dataComplain }) {
    let complainDate, complainTime;
    let finishDate = 'Not finish yet';
    let finishTime = 'Not finish yet';
    let finishBy = 'Not finish yet';

    if (Object.keys(dataComplain).length > 0) {
        const dateComplain = new Date(dataComplain.complaint_date);

        complainDate = dateComplain.getDate() + ' ' + MONTHS[dateComplain.getMonth()] + ' ' + dateComplain.getFullYear();
        complainTime = dateComplain.getHours() + ':' + dateComplain.getMinutes() + ':' + dateComplain.getSeconds();

        if (dataComplain.finish_at !== null) {
            const dateFinish = new Date(dataComplain.finish_at);

            finishDate = dateFinish.getDate() + ' ' + MONTHS[dateFinish.getMonth()] + ' ' + dateFinish.getFullYear();
            finishTime = dateFinish.getHours() + ':' + dateFinish.getMinutes() + ':' + dateFinish.getSeconds();
        }

        if (dataComplain.finish_by !== null) {
            finishBy = dataComplain.finish_by;
        }
    }
    return (
        <>
            <div>
                {
                    Object.keys(dataComplain).length > 0 &&
                    <>
                        <Image
                            path={dataComplain.image}
                            className="w-2/4 mx-auto mb-3 object-cover"
                        />
                        <InputWithLabel
                            classInput={'!mb-3'}
                            value={dataComplain.brand_name}
                            label={'Brand Name'}
                            readOnly={true}
                        />
                        <InputWithLabel
                            classInput={'!mb-3'}
                            value={dataComplain.vendor_name}
                            label={'Vendor Name'}
                            readOnly={true}
                        />
                        <div className="columns-2">
                            <InputWithLabel
                                classInput={'!mb-3'}
                                value={complainDate}
                                label={'Complain Date'}
                                readOnly={true}
                            />
                            <InputWithLabel
                                classInput={'!mb-3'}
                                value={complainTime}
                                label={'Complain Time'}
                                readOnly={true}
                            />
                        </div>
                        <InputWithLabel
                            classInput={'!mb-3'}
                            value={dataComplain.title}
                            label={'Title'}
                            readOnly={true}
                        />
                        <TextAreaWithLabel
                            classInput={'!mb-3 valid-form !text-xl !font-medium'}
                            value={dataComplain.comment}
                            label={'Detail Complain'}
                            readOnly={true}
                        />
                        <div className="columns-2">
                            <InputWithLabel
                                classInput={'!mb-3'}
                                value={finishDate}
                                label={'Finish Date'}
                                readOnly={true}
                            />
                            <InputWithLabel
                                classInput={'!mb-3'}
                                value={finishTime}
                                label={'Finish Time'}
                                readOnly={true}
                            />
                        </div>
                        <InputWithLabel
                            classInput={'!mb-3'}
                            value={finishBy}
                            label={'Finish By'}
                            readOnly={true}
                        />
                    </>
                }
            </div>
        </>
    );
}

export default FormDetailComplain;